import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { GatsbyImage } from "gatsby-plugin-image"

const LyneHomeBannerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  color: #fff;
  padding: 0;
`

const DesktopBannerWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 400px;

  .gatsby-image-wrapper {
    width: 100%;
  }

  @media (max-width: 980px) {
    min-height: 350px;
  }

  @media (max-width: 850px) {
    display: none;
  }

  img {
    object-fit: cover;
  }
`

const TabletBannerWrapper = styled.div`
  display: none;
  width: 100%;
  min-height: 320px;

  @media (max-width: 850px) {
    display: flex;
  }

  @media (max-width: 550px) {
    display: none;
  }

  .gatsby-image-wrapper {
    width: 100%;
  }

  img {
    object-fit: cover;
    position: absolute;
  }
`

const MobileBannerWrapper = styled.div`
  display: none;

  @media (max-width: 550px) {
    display: flex;
    width: 100%;
    min-height: 185px;
  }

  .gatsby-image-wrapper {
    width: 100%;
  }

  img {
    object-fit: cover;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-position: center center;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  position: absolute;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 20px;
  max-width: 1200px;
  width: 100%;

  @media (max-width: 850px) {
    left: 0%;
  }

  @media (max-width: 550px) {
    background: #a5c4e1;
    display: flex;
    position: relative;
    border: 0px;
    padding: 15px 20px;
    max-width: 100%;
    width: 100%;
    margin-top: -1px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 50%;
  max-width: ${({ lang }) => (lang === "es" ? "400px" : "430px")};

  @media (max-width: 1080px) {
    max-width: ${({ lang }) =>
      lang === "de" || lang === "es" ? "300px" : "300px"};
  }

  @media (max-width: 550px) {
    max-width: 100%;
    align-items: center;
    text-align: center;
  }
`

const Title = styled.h1`
  color: #262626;
  font-size: ${({ lang }) => (lang === "de" ? "34px" : "32px")};
  margin: 0px;
  line-height: 1;
  font-family: "Gotham";
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 15px;
  position: relative;
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1080px) {
    font-size: ${({ lang }) => (lang === "de" ? "24px" : "32px")};
  }

  @media (max-width: 850px) {
    max-width: 100%;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 550px) {
    width: 100%;
    align-items: center;
    font-size: 28px;
  }

  @media (max-width: 400px) {
    font-size: ${({ lang }) => (lang === "de" ? "21px" : "24px")};
  }

  @media (max-width: 340px) {
    font-size: ${({ lang }) => (lang === "de" ? "21px" : "21px")};
  }
`

const Separator = styled.div`
  height: 4px;
  background: #fade14;
  background: #fa7f3f;
  width: 100%;
  max-width: 150px;
  margin-bottom: 15px;

  @media (max-width: 550px) {
    margin-top: 5px;
    max-width: 260px;
  }
`

const BottomText = styled.div`
  font-family: "Gotham Book";
  font-weight: bold;
  font-size: 21px;
  line-height: 1;
  margin-bottom: 15px;
  color: #262626;

  p {
    margin: 0px;
  }

  @media (max-width: 550px) {
    font-size: 18px;
  }

  @media (max-width: 360px) {
    font-size: 16px;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;

  @media (max-width: 550px) {
    justify-content: center;
  }
`

const LogoImage = styled.img`
  width: 100%;
  margin: 0px;
  max-width: 250px;
  margin-right: 20px;
  object-fit: contain;

  @media (max-width: 550px) {
    margin-right: 15px;
    max-width: 200px;
  }

  @media (max-width: 400px) {
    max-width: 160px;
  }
`

const TopText = styled.div`
  font-family: "Gotham";
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  color: #262626;
  padding: 8px 10px;
  background: #fff;
  border-radius: 15px 0px 15px 0px;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 850px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 550px) {
    width: 100%;
  }
`

const Button = styled(Link)`
  background-color: #262626;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  line-height: 18px;
  max-width: 100%;
  font-family: "GothamLight";
  font-weight: bold;
  text-align: center;
  padding: 10px 20px;

  :hover {
    color: #fff;
  }

  @media (max-width: 550px) {
    width: 100%;
    font-size: 16px;
  }
`

const ProductLink = styled(Link)`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
`

const ProductLinkTitle = styled.span`
  display: none;
`

export const LyneHomeBanner = ({ intl, data }) => (
  <LyneHomeBannerWrapper>
    <DesktopBannerWrapper>
      <GatsbyImage
        objectFit="cover"
        image={
          data.top_banner_desktop_image.localFile.childImageSharp
            .gatsbyImageData
        }
      />
    </DesktopBannerWrapper>
    <TabletBannerWrapper>
      <GatsbyImage
        objectFit="cover"
        image={
          data.top_banner_tablet_image.localFile.childImageSharp.gatsbyImageData
        }
      />
    </TabletBannerWrapper>
    <MobileBannerWrapper>
      <GatsbyImage
        objectFit="cover"
        image={
          data.top_banner_mobile_image.localFile.childImageSharp.gatsbyImageData
        }
      />
    </MobileBannerWrapper>
    <ContentWrapper>
      <TextWrapper>
        {data.top_banner_text.title && (
          <Title
            dangerouslySetInnerHTML={{
              __html: data.top_banner_text.title,
            }}
          />
        )}
        {/* <Separator /> */}
        {data.top_banner_text.logo_image && (
          <LogoWrapper>
            {data.top_banner_text.logo_image && (
              <LogoImage
                className="lazyload"
                data-src={data.top_banner_text.logo_image}
              />
            )}
            {/* {data.top_banner_text.top_text && (
              <TopText
                dangerouslySetInnerHTML={{
                  __html: data.top_banner_text.top_text,
                }}
              />
            )} */}
          </LogoWrapper>
        )}
        {data.top_banner_text.text && (
          <BottomText
            dangerouslySetInnerHTML={{
              __html: data.top_banner_text.text,
            }}
          />
        )}
        <ButtonWrapper>
          {data.top_banner_text.button_text &&
            data.top_banner_text.button_url && (
              <Button to={data.top_banner_text.button_url}>
                {data.top_banner_text.button_text}
              </Button>
            )}
        </ButtonWrapper>
      </TextWrapper>
    </ContentWrapper>
    {data.top_banner_text.button_url && (
      <ProductLink to={data.top_banner_text.button_url}>
        <ProductLinkTitle></ProductLinkTitle>
      </ProductLink>
    )}
  </LyneHomeBannerWrapper>
)

export default injectIntl(LyneHomeBanner)
